import React from 'react';
import { NavLink, withRouter }  from 'react-router-dom';
import { Text } from '../components/Language/Language';
import './footer.css';

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.innerNavBar = React.createRef();
    }
    
    render() {
        return (
          <footer className="footer-black">
            <div className="footer-container">
                <NavLink to="/Politicas_de_Privacidad"><Text tid="footerPolicy" /></NavLink>
            </div>
            <br/>
            <span>KAPIAPP S.R.L © {new Date().getFullYear()}.</span>
          </footer>
        )
    }
};
Footer = withRouter(Footer);
export default Footer;
