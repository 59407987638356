import React , { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Progress from 'react-progress-2';
import axios from 'axios';

import Navbar from '../../components/navbar';
import { Text, LanguageContext } from '../../components/Language/Language';
import ScrollTop from '../../components/scrollTop';
import './players.css';

import LOGO_BANNER from '../../resources/img/banner-players.png';
import CHICO from '../../resources/img/chico (1).svg';
import TIME from '../../resources/img/icon (2).svg';
import SELECT from '../../resources/img/XMLID_1341_.svg';
import PLAYER from '../../resources/img/Grupo 3778.png';
import SUM from '../../resources/img/Visto bueno.svg';
import VALORATION from '../../resources/img/Valoracion.png';
import PLUS from '../../resources/img/Mas.svg';
import CHAT from '../../resources/img/Chat.png';
import SEARCH from '../../resources/img/lupa.png';
import VALORATION_Y from '../../resources/img/Valoracion amarilla.svg';
import TLFN from '../../resources/img/telefono.png';
import CELLPHONE from '../../resources/img/cellphone.png';
import PLAY_STORE from '../../resources/img/google_play.png';
import APP_STORE from '../../resources/img/app_store.png';
import MAP from '../../resources/img/map@2x.png';


class Players extends Component {

    constructor(props){
        super(props);
        this.state = {
            asist: true,
            name: '',
            email: '',
            message: '',
        };
        this.about = React.createRef();
        this.how = React.createRef();
    }
    static contextType = LanguageContext;

    setTitle = (context) => 
    { 
        document.title = context.dictionary['titlePlayers'];
    }

    validateForm = (e) => {
        e.preventDefault();
        const { name, email, message } = this.state;
        let error = false;
        if (name.trim() === '' ) {
            toast.error(this.context.dictionary['nameRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            toast.error(this.context.dictionary['emailRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        if (message.trim() === '' ) {
            toast.error(this.context.dictionary['messageRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        if (error) return;
            this.handleFormSubmit();
    }
    
    handleFormSubmit(e) {
        const { name, email, message } = this.state;
        this.setState({ loadingMess: true });
        Progress.show();
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}`,
          headers: { "content-type": "application/json", "Access-Control-Allow-Origin": "*" },
          data: { name, email, message, subject: 'Kapi Jugadores' }
        })
          .then(result => {
            if (result.data.sent) {
              this.setState({ 
                                error: false, 
                                loadingMess: false,
                                name: '',
                                email: '',
                                message: '',
                            });
              toast.success(this.context.dictionary['contactSuccess'], {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
              });
            } else {
              toast.error(this.context.dictionary['contactError'], {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
              this.setState({ error: true, loadingMess: false });
            }
          })
          .catch(error => {
            this.setState({ error: error.message, loadingMess: false });
            toast.error(this.context.dictionary['contactError'], {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000
            });
          })
          .finally(() => { Progress.hide(); });
    }

    render() {
        const { asist } = this.state;
        return (
            <div>
                <Navbar 
                    sel="players"
                    clickAbout={(nav) => { window.scrollTo(0, this.about.current.offsetTop - 80 - nav.current.clientHeight ) }} 
                    clickHow={(nav) => { window.scrollTo(0, this.how.current.offsetTop - nav.current.clientHeight ) }} 
                />
                <ToastContainer autoClose={false} />
                <section className="section-padding">
                    <div style={{ position: 'relative' }}>
                        <img src={LOGO_BANNER} alt="banner" style={{ width:'100%' }}></img>
                        <div className="download-app-store">
                            <a href="https://play.google.com/store/apps/details?id=com.kickup&hl=es" target="_blank" rel="noopener noreferrer">
                                <img src={PLAY_STORE} alt="play_store" className="store-icon"></img>
                            </a>
                            <a href="https://apps.apple.com/app/kickup/id1473469273" target="_blank" rel="noopener noreferrer">
                                <img src={APP_STORE} alt="play_store" className="store-icon"></img>
                            </a>
                        </div>
                    </div>
                </section>

                <div className="happen-title">
                    <h1><Text tid="textHappendT1"></Text></h1>
                </div>
                <section className="section-padding section-happen">
                    <div className="row">
                        <div className="col-xs-12 col-md-4 text-center">
                            <img src={TIME} alt="chico" className="happen-icon"></img>
                            <div className="happend-text">
                                <span className="monserrat-semibold"><Text tid="subTextHappendC1"></Text></span>
                                <span><Text tid="subTextHappendC1L2"></Text></span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 text-center">
                            <img src={SELECT} alt="chico" className="happen-icon"></img>
                            <div className="happend-text">
                                <span className="monserrat-semibold"><Text tid="subTextHappendC2"></Text></span>
                                <span><Text tid="subTextHappendC2L2"></Text></span>
                            </div>
                        </div>
                        <div className="col col-xs-12 col-md-4 text-center">
                            <img src={CHICO} alt="chico" className="happen-icon"></img>
                            <div className="happend-text">
                                <span className="monserrat-semibold"><Text tid="subTextHappendC3"></Text></span>
                                <span><Text tid="subTextHappendC3L2"></Text></span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding">
                    <div className="row section-kapi-logo">
                        <div className="col-xs-12 col-md-12 text-center">
                            <img src="/img/LOGO.png" className="kapi-logo" alt="logo"/>
                            <p><span className="kapi-title-d">Kapi</span> <Text tid="textKapiExplain"></Text></p>
                        </div>
                    </div>
                </section>
                <section ref={this.how} className="section-padding ">
                    <div className="row">
                        <div className="col col-xs-6 text-center">
                            <button className={asist ? 'btn btn-kapi-active' : 'btn btn-kapi'} onClick={() => this.setState({ asist: true })}><Text tid="textButtonPlay"></Text></button>
                        </div>
                        <div className="col col-xs-6 text-center">
                            <button className={asist ? 'btn btn-kapi' : 'btn btn-kapi-active'} onClick={() => this.setState({ asist: false })}><Text tid="textButtonSetup"></Text></button>
                        </div>
                    </div>
                    {asist ? (
                        <div className="row modality-play">
                            <div className="col-xs-12 col-md-4 text-center">
                                <img src={PLAYER} alt="chico" className="happen-icon"></img>
                                <div className="happend-text">
                                    <span className="monserrat-semibold title"><Text tid="textCustomize"></Text></span>
                                    <span><Text tid="textCustomizeSub"></Text></span>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 text-center">
                                <img src={SUM} alt="chico" className="happen-icon"></img>
                                <div className="happend-text" style={{ width: '100%' }}>
                                    <span className="monserrat-semibold title"><Text tid="textJoin"></Text></span>
                                    <span><Text tid="textJoinSub"></Text></span>

                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 text-center">
                                <img src={VALORATION} alt="chico" className="happen-icon"></img>
                                <div className="happend-text">
                                    <span className="monserrat-semibold title"><Text tid="textRating"></Text></span>
                                    <span><Text tid="textRatingSub"></Text></span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row modality-play">
                            <div className="col-xs-12 col-md-4 text-center">
                                <img src={PLUS} alt="chico" className="happen-icon"></img>
                                <div className="happend-text">
                                    <span className="monserrat-semibold title"><Text tid="textCreateTitle"></Text></span>
                                    <span><Text tid="textCreateSubTitle"></Text></span>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 text-center">
                                <img src={SEARCH} alt="chico" className="happen-icon"></img>
                                <div className="happend-text">
                                    <span className="monserrat-semibold title"><Text tid="textSearchTitle"></Text></span>
                                    <span><Text tid="textSearchSubTitle"></Text></span>

                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 text-center">
                                <img src={CHAT} alt="chico" className="happen-icon"></img>
                                <div className="happend-text">
                                    <span className="monserrat-semibold title"><Text tid="textConfirmTitle"></Text></span>
                                    <span><Text tid="textConfirmSubTitle"></Text></span>
                                </div>
                            </div>
                        </div>
                    )}
                </section>

                <section className="section-padding section-valoration">
                    <div className="text-center valoration-content">
                        <span className="quote">“</span>
                        <img src={VALORATION_Y} alt="valoration"></img>
                        <h4 className="valotarion-title"><Text tid="textRatingTitle"></Text></h4>
                        <p>
                            <Text tid="textRatingSubTitle"></Text>
                        </p>
                        <span className="quote invert">“</span>
                    </div>
                </section>


                <section className="section-padding section-download">
                    <div className="row section-download-back">
                        <div className="col-xs-12 col-md-7">
                            <div className="banner-download">
                                <h1><Text tid="textDownloadApp"></Text></h1>
                                <div className="row text-center download-area">
                                    <div className="col-xs-12 col-md-12 col-12 justify-content-between">
                                        <a href="https://play.google.com/store/apps/details?id=com.kickup&hl=es" target="_blank" rel="noopener noreferrer">
                                            <img src={PLAY_STORE} alt="play_store" className="store-icon"></img>
                                        </a>
                                        <a href="https://apps.apple.com/app/kickup/id1473469273" target="_blank" rel="noopener noreferrer">
                                            <img src={APP_STORE} alt="play_store" className="store-icon"></img>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-5">
                            <div className="tlf-img">
                            <LanguageContext.Consumer>
                            {(context) => (
                                 <img key={context.language.id} src={context.language.id === 'es' ? TLFN : CELLPHONE} alt="app" className="img-resp" style={{ maxWidth: '80%' }}></img>
                            )}
                            </LanguageContext.Consumer>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-section section-padding">
                    <div className="container-fluid align-items-center">

                        <div className="contact-form-group">
                            <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <div className="row text-center">
                                    <div className="col-md-12 col-xs-12">
                                        <div style={{ textAlign: 'left', marginBottom: '2rem' }}>
                                            <span className="contact-font"><Text tid="textContactUs"></Text></span>
                                        </div>
                                        <form className="contact-form">
                                            <LanguageContext.Consumer>
                                            {(context) => (
                                                <React.Fragment>
                                                {this.setTitle(context)}
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                        <input type="text" placeholder={context.dictionary['contactName']} value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                    <input type="email" placeholder="Email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }}  />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                        <textarea rows="5" placeholder={context.dictionary['contactMessage']} value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} ></textarea>
                                                    </div>
                                                </div>
                                                </React.Fragment>
                                            )}
                                            </LanguageContext.Consumer>
                                            <div className="row">
                                                <div className="col col-xs-12">
                                                    <button type="submit" className="btn theme-btn" disabled={this.state.loadingMess} onClick={this.validateForm.bind(this)}><Text tid="contactSend"></Text></button>
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <div className="row">
                                    <div className=" col-xs-12 col-sm-12 col-md-12 right-contact">
                                        <div style={{ marginBottom: '2rem' }}>
                                            <span className="contact-font">Talcahuano 758 1"B", Caba, Argentina</span>
                                        </div>
                                        <img src={MAP} alt="map" className="img-fluid map-img"></img>
                                        <br></br>
                                        <p><Text tid="contactFollow"></Text></p>
                                        <div className="socials-icons">
                                            <a href="#kapi" target="_blank" rel="noopener noreferrer">
                                            <img src={"/img/icons/Linkedin_2.svg"} alt="instagram" className="img-fluid icons-socials" />
                                            </a>
                                            <a href="#kapi" target="_blank" rel="noopener noreferrer">
                                            <img src={"/img/icons/Instagram_2.svg"} alt="Twitter" className="img-fluid icons-socials" />
                                            </a>
                                            <a href="#kapi" target="_blank" rel="noopener noreferrer">
                                            <img src={"/img/icons/Facebook_2.svg"} alt="TikTok_" className="img-fluid icons-socials" />
                                            </a>
                                        
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ScrollTop />

            </div>
        )
    }
}

export default Players;
