import React from 'react';
import './scrollTop.css';


class ScrollTop extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showScrollToTop: false,
        }
    }

    componentDidMount() {
        this.checkForScrollToTop();
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    checkForScrollToTop() {
        if (
            document.body.scrollTop > this.props.distance ||
            document.documentElement.scrollTop > this.props.distance
        ) {
            this.setState({
                showScrollToTop: true
            });
        } else {
            this.setState({
                showScrollToTop: false
            });
        }
    }

    handleScroll() {
        this.checkForScrollToTop();
    }

    scrollUp() {
        const { performance, requestAnimationFrame } = window;

        const { speed, target } = this.props;
        if (
            speed <= 0 ||
            typeof performance === "undefined" ||
            typeof requestAnimationFrame === "undefined"
        ) {
            return this.setScrollTop(target);
        }
        const start = performance.now();
        const initScrollTop = this.getScrollTop();
        const pxsToScrollBy = initScrollTop - target;

        const that = this;
        requestAnimationFrame(step);

        function step(timestamp) {
            const delta = timestamp - start;
            const progress = Math.min(delta / speed, 1);
            that.setScrollTop(initScrollTop - Math.round(progress * pxsToScrollBy));
            if (progress < 1) {
                requestAnimationFrame(step);
            }
        }
    }

    getScrollTop() {
        return (
            document.body.scrollTop ||
            ((document.documentElement && document.documentElement.scrollTop) || 0)
        );
    }

    setScrollTop(value) {
        document.body.scrollTop = value;
        if (document.documentElement) {
            document.documentElement.scrollTop = value;
        }
    }

    render() {
        const { showScrollToTop } = this.state;
        let className = "scroll-top";
        if (this.props.className) {
          className = `${className} ${this.props.className}`;
        }
        return (
            showScrollToTop && (<div className={className} onClick={this.scrollUp.bind(this)}><i className="fa fa-arrow-up"></i></div>)
        )
    }
};
ScrollTop.defaultProps = {
    distance: 50,
    speed: 250,
    target: 0
  };
export default ScrollTop;