const es = {
    navAbout: '¿Qué somos?',
    navFields: 'Kapi Canchas',
    navPlayers: 'Kapi Jugadores',
    navHowWorks: 'Como funciona',
    homeWe: '¡Somos',
    homeNewForm: '“La nueva forma de jugar fútbol”',
    joinPlayers: 'Conectamos a jugadores con el fútbol.',
    textPlayers: 'Kapi conecta comunidades de fútbol. Desde el jugador ocasional, al amateur o semi profesional. Permite gestionar cada paso necesario para la ejecución del juego. Planificando los encuentros, chateando con los jugadores, encontrando los que falten, valorando a los que jugaron contigo y llevando un perfil actualizado con todas las estadísticas para que vivas con intensidad la experiencia del fútbol. Con Kapi siempre podés jugar.',
    textPlayers1: 'Kapi ayuda a crear comunidades de fútbol, desde el jugador ocasional o amateur hasta el jugador semi-profesional. Gestionamos los pasos necesarios para la planificación y ejecución de cada juego.',
    textPlayers2: 'Planea los encuentros, valora a los que participaron contigo, chatea con otros jugadores y completa tu equipo si alguien se da de baja.',
    textPlayers3: 'Kapi lleva un perfil actualizado con todas las estadísticas para que vivas con intensidad la experiencia del fútbol.',
    textPlayers4: 'Desde la planificación a la organización de un encuentro, nuestras herramientas personalizan tu experiencia para que juegues como nunca antes.',
    textPlayers5: 'Creando comunidades de fútbol y uniendo jugadores,',
    textPlayers6: 'Kapi llega para revolucionar la experiencia de juego que tenemos.',
    homePickSide: 'Elige tu',
    homePickSide1: 'lado',

    textPlayersP1: 'busca facilitar el desarrollo de los partidos de fútbol. Permitiendo descubrir encuentros que se adapten a las necesidades de cada usuario.',
    textPlayersP2: 'Desde la planificación del partido brinda todas las herramientas necesarias para organizar y llevar a cabo cada paso para jugar.',
    textPlayersP3: 'Conectando comunidades y jugadores de fútbol, Kapi llega para revolucionar, como nunca antes, la forma en la que vivimos la experiencia del fútbol. ',
    textHappendT1: '¿Te ha pasado?',
    subTextHappendC1: 'No se ponen de acuerdo',
    subTextHappendC1L2: 'entre tantos jugadores para fijar un horario de partido.',
    subTextHappendC2: 'Se cancela el partido ',
    subTextHappendC2L2: 'porque a último minuto un jugador se da de baja.',
    subTextHappendC3: 'Quieres jugar fútbol',
    subTextHappendC3L2: 'pero no tienes con quién jugar.',
    textKapiExplain: 'está ideado para adaptar los diferentes procesos del fútbol a tus necesidades.',
    textButtonSetup: 'Si te gusta organizar partidos',
    textButtonPlay: 'Si quieres asistir a un partido',
    textCustomize: 'Customiza',
    textCustomizeSub: 'Arregla tú perfil segun el tipo de jugador que eres.',
    textJoin: 'Sumate',
    textJoinSub: 'Encuentra un partido, utilizando los filtros y confirma asistencia al partido de tu preferencia.',
    textRating: 'Valora',
    textRatingSub: 'Al finalizar el partido, marca el rendimiento de cada jugador.',
    textRatingTitle: 'Valoraciones',
    textRatingSubTitle: 'Al finalizar cada partido los jugadores pueden destacar las habilidades de cada miembro a través de nuestro sistema de valoraciones.',
    textCreateTitle: 'Crea',
    textCreateSubTitle: 'Organiza un partido con las especificaciones que desees.',
    textSearchTitle: 'Busca',
    textSearchSubTitle: 'Invita jugadores si no se ha completado su partido.',
    textConfirmTitle: 'Chat',
    textConfirmSubTitle: 'Confirma asistencia con los jugadores del partido mediante su chat exclusivo.',

    textDownloadApp: 'Descarga la app y juega cuando quieras.',
    textContactUs: 'Contactanos',
    contactName: 'Nombre',
    contactCompany: 'Nombre del predio',
    contactMessage: 'Mensaje',
    contactSend: 'Enviar',
    contactFollow: 'Síguenos en:',

    kapiFieldTitle: 'Con Kapi',
    kapiFieldTitle2: 'tienes el control',
    kapiFieldTitle3: 'de tu cancha',
    preKapiFieldText: 'Con nuestro sistema administrativo para canchas ',
    kapiFieldText: 'completamos todos tus espacios, aumentamos tus reservas y puedes gestionarlo todo desde nuestro software. Te conectamos con jugadores que buscan lo que ofreces, ¡fútbol! Porque Kapi es la nueva forma de reservar tus canchas.',
    textContactField: 'Puedes probar nuestra versión preliminar completando los datos a continuación.',
    textContactSoon: 'Te contactaremos pronto.',

    homeTitle: 'Siempre se puede jugar al fútbol',
    homePlayers: 'Jugadores',
    homeFields: 'Canchas',

    titlePlayers: 'Jugadores | Kapi',
    titleFields: 'Canchas | Kapi',

    nameRequired: 'Ingrese un nombre',
    nameCompanyRequired: 'Ingrese Nombre del predio',
    emailRequired: 'Ingrese un email valido',
    messageRequired: 'Mensaje es requerido',
    contactSuccess: 'Mensaje Enviado',
    contactError: 'Error al enviar, intente nuevamente',

    footerPolicy: 'Politicas de privacidad',
  };
  
export default es;
