import React from 'react';
import { NavLink, withRouter }  from 'react-router-dom';
import LanguageSelector from '../components/Language/languageSelector';
import { Text } from '../components/Language/Language';
import './navbar.css';
import LOGO_K from '../resources/img/k_amarilla.svg';

class Navbar extends React.Component {
    constructor(props){
        super(props);
        this.innerNavBar = React.createRef();
    }
    
    render() {
        return (
            <header ref={this.innerNavBar} className="header fixed-top">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink to="/" className="navbar-brand">
                                <img src={LOGO_K} alt="" className="img-fluid nav-logo" />
                            </NavLink>
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <ul className="navbar-nav ml-auto">
                                    <li className="link-nav">
                                    <NavLink to="/" className={this.props.sel === 'about' ? 'selected-nav' : ''}><Text tid="navAbout" /></NavLink>
                                        {this.props.sel === 'about' && (
                                            <div className="point black small"></div>
                                        )}
                                    </li>
                                    <li className="link-nav">
                                        <NavLink to="/players" className={this.props.sel === 'players' ? 'selected-nav' : ''}><Text tid="navPlayers"></Text></NavLink>
                                        {this.props.sel === 'players' && (
                                            <div className="point small"></div>
                                        )}
                                    </li>
                                    <li className="link-nav"><NavLink to="/fields"><Text tid="navFields"></Text></NavLink></li>
                                </ul>
                                <ul className="navbar-nav ml-auto">
                                    <LanguageSelector />
                                </ul>
                            </div>
                    </nav>
                </div>
            </header>
        )
    }
};
Navbar = withRouter(Navbar);
export default Navbar;