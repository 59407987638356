const en = {
    navAbout: 'About us',
    navFields: 'Kapi Fields',
    navPlayers: 'Kapi Players',
    navHowWorks: 'How it works',
    homeWe: 'We are',
    homeNewForm: '“The new way to play soccer”',
    joinPlayers: 'Connecting players to football.',
    textPlayers: 'Kapi connects football communities. From the casual player to amateur or semi-professional. It allows you to manage every step necessary for the game. Planning the meetings, chatting with players, finding the player that you want, rating the players who played with you and keeping an updated profile with all the statistics for you to live with intensity the experience of football. With Kapi you can always play.',
    textPlayers1: 'Kapi helps to build a soccer community from the amateur to the semi-pro player. We manage every step of each game’s planification process.',
    textPlayers2: 'Schedule games, rate players, chat with other users and complete your team if someone bails last minute.',
    textPlayers3: 'Kapi has an updated profile with all the metrics you need to have the perfect soccer experience.',
    textPlayers4: 'From planning to organization of a match, our tools will customize your soccer experience like never before. ',
    textPlayers5: 'Creating soccer communities and linking players,',
    textPlayers6: 'Kapi will transform your game experience!!',
    homePickSide: 'Pick your',
    homePickSide1: 'side',
    textPlayersP1: 'seeks to facilitate the setting up of football matches. It allows you to discover matches that are adapted to your needs.',
    textPlayersP2: 'From the planning of the match it provides all the necessary keys to organize and carry out every step to play.',
    textPlayersP3: 'Connecting communities and football players, Kapi comes to revolutionise, as never before, the way that we live the football experience.',
    textHappendT1: 'Can you relate?',
    subTextHappendC1: 'You and other players ',
    subTextHappendC1L2: 'can’t agree on a date and time for the match.',
    subTextHappendC2: 'The match gets cancelled',
    subTextHappendC2L2: 'because someone bails last minute.',
    subTextHappendC3: 'You want to play soccer',
    subTextHappendC3L2: 'but don’t have anyone to play with.',
    textKapiExplain: 'is here to adapt every game process to your needs.',
    textButtonSetup: 'If you like to set up football matches',
    textButtonPlay: 'If you want to play football',
    textCustomize: 'Customize',
    textCustomizeSub: 'Set up your profile according to the kind of player you are.',
    textJoin: 'Join football games',
    textJoinSub: 'Find a match, use the filters and confirm attendance at the match of your choice.',
    textRating: 'Rate',
    textRatingSub: 'At the end of the match, rates the performance of each player.',
    textRatingTitle: 'Rating',
    textRatingSubTitle: 'At the end of every game, you can rate the skills of other players through our rating system.',
    textCreateTitle: 'Create',
    textCreateSubTitle: 'Organize your game with the specs you want.',
    textSearchTitle: 'Search',
    textSearchSubTitle: 'Invite other players to your game if you have availability.',
    textConfirmTitle: 'Chat',
    textConfirmSubTitle: 'Confirm assistance by chatting with other players.',

    textDownloadApp: 'Download the app and be ready to play!',
    textContactUs: 'Contact us',
    contactName: 'Name',
    contactCompany: 'Company Name',
    contactMessage: 'Message',
    contactSend: 'Send',
    contactFollow: 'Follow us:',

    kapiFieldTitle: 'Kapi gives you',
    kapiFieldTitle2: 'absolute control',
    kapiFieldTitle3: 'over your field!',
    preKapiFieldText: 'With our field administrative software, ',
    kapiFieldText: 'we fill any empty slot in your schedule, rise your reservations and you can manage everything through our app. We link you with players that want what you offer, to play soccer! Kapi is the new way to make reservations.',
    textContactField: 'You can preview our beta version by subscribing',
    textContactSoon: 'We’ll get in touch as soon as possible!',

    homeTitle: 'You can always play',
    homePlayers: 'Players',
    homeFields: 'Fields',

    titlePlayers: 'Players | Kapi',
    titleFields: 'Fields | Kapi',

    nameRequired: 'Name is required',
    nameCompanyRequired: 'Company name is required',
    emailRequired: 'Invalid Email',
    messageRequired: 'Message is required',
    contactSuccess: 'Message Sended',
    contactError: 'Error, try aganin',
    
    footerPolicy: 'Privacy policy',
  };
  
export default en;
