import React, { Component } from 'react';
import { NavLink }  from 'react-router-dom';
import Navbar from '../../components/navbar';
import LOGO_K from '../../resources/img/k_amarilla.svg';
import ScrollTop from '../../components/scrollTop';
import { LanguageContext } from '../../components/Language/Language';
import '../../components/navbar.css';
import './terms.css';

class Terms extends Component { 
    componentDidMount() {
        document.title = 'Política de Privacidad - Kapi';
    }

    renderEsp() {
        return (
            <React.Fragment>
                <section className="section-padding section-terms">
                    <h1>Política de Privacidad</h1>
                    <p>La presente Política de Privacidad establece los términos en que usa y protege la información que es proporcionada a KAPIAPP S.R.L comercializada como KAPI ('KAPI') por usted ( el Usuario”) al momento de utilizar nuestra aplicación o página web. KAPI está comprometida con la seguridad de los datos de sus Usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que la información y datos sólo se emplearán de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>
                
                    <p>Si usted no está de acuerdo con alguno de los términos de esta Política de Privacidad, por favor no utilice la página ni proporcione ningún tipo de información personal.</p>

                    <h2>Información que es recogida</h2>

                    <p>Entre las clases de Datos Personales que KAPI recoge, ya sea directamente o a través de terceros, se encuentran: Cookie, Datos de uso, Nombre, Apellido(s), Número de teléfono, Dirección de correo electrónico, Sexo, Fecha de Nacimiento, Contraseña, Ciudad e imagen. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.</p>

                    <p>Otros Datos Personales recogidos pueden encontrarse descritos en otros apartados de la presente política de privacidad o en otro documento específico mostrado contextualmente en el momento de la recogida de dichos Datos.</p>

                    <p>Los Datos Personales pueden ser proporcionados por el Usuario por su propia voluntad, o bien ser recogidos automáticamente durante la utilización de los servicios de KAPI.</p>

                    <p>Salvo que se indique lo contrario, el uso de Cookies – o de otras medios de seguimiento por KAPI o por los propietarios de servicios de terceros utilizados por KAPI tiene como finalidad la identificación de los Usuarios y el registro de sus preferencias, con la única finalidad de prestar el servicio solicitado por el Usuario.</p>

                    <p>La negativa del Usuario a proporcionar ciertos Datos Personales podría imposibilitar a KAPI la prestación de sus servicios.</p>

                    <p>El Usuario asume la responsabilidad por los Datos Personales de terceros publicados o compartidos mediante KAPI y declara que tiene derecho a comunicarlos o difundirlos públicamente, liberando al Responsable de Tratamiento de toda responsabilidad al respecto.</p>

                    <h2>Uso de la información recogida</h2>

                    <p>KAPI emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios, así como para las siguientes finalidades: Estadísticas, Interacción con redes sociales y plataformas externas, Interacción con las plataformas de asistencia y de feedback, visualizar contenidos de plataformas externas, contactar con el Usuario, registro y autenticación. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindar algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.</p>

                    <h2>Cookies</h2>

                    <p>Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su dispositivo, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.</p>

                    <p>KAPI emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente, visitas a una web . Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su dispositivo para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.</p>

                    <h2>Control de su información personal</h2>

                    <p>Los titulares a los que se refieren los Datos Personales tienen derecho a obtener en cualquier momento la confirmación de que estos han sido almacenados por el Responsable de Tratamiento, a conocer su contenido y origen, a verificar su exactitud o solicitar que sean completados, cancelados, actualizados o rectificados, a que sean anonimizados o a que se bloqueen aquellos Datos Personales que están siendo tratados en contravención de las leyes, así como a oponerse a su tratamiento por cualquier motivo legítimo. Las solicitudes deberán remitirse al Responsable de Tratamiento utilizando los datos de contacto indicados anteriormente.</p>

                    <p>En cualquier momento usted puede restringir la recopilación o el uso de Datos Personales que es proporcionada a nuestra aplicación o sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.</p>

                    <p>KAPI no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>

                    <h2>Permisos de Facebook solicitados por Kapi.</h2>

                    <p>KAPI podrá solicitar permisos de Facebook que le permitan realizar acciones en la cuenta de Facebook del Usuario y recuperar información de ésta, incluyendo Datos Personales. Este servicio permite a KAPI conectarse con la cuenta del Usuario en la red social Facebook, proporcionada por Facebook Inc.</p>
                    <p>Para obtener más información acerca de los siguientes permisos, diríjanse a la documentación de los permisos de Facebook y a la política de privacidad de Facebook. Los permisos solicitados son los siguientes: nombre, ciudad actual, fecha de nacimiento, dirección de correo electrónico, información básica.</p>
                    <p>Este servicio permite a KAPI conectarse con la cuenta del Usuario en la red social Facebook, proporcionada por Facebook Inc.</p>
                    <p>Permisos solicitados: Ciudad actual, Fecha de nacimiento, Dirección de correo electrónico.</p>

                    <h2>Actualización o corrección de su información</h2>

                    <p>Los derechos que tiene para solicitar actualizaciones o correcciones de la información que KAPI recopila dependen de su relación con KAPI. El personal puede actualizar o corregir su información según se detalla en las políticas internas de empleo de nuestra empresa.</p>
                    <p>Los clientes tienen derecho a solicitar la restricción de ciertos usos y divulgaciones de información personal identificable de la siguiente manera. Puede ponerse en contacto con nosotros para (1) actualizar o corregir su información de identificación personal, (2) cambiar sus preferencias con respecto a las comunicaciones y otra información que reciba de nosotros, o (3) eliminar la información de identificación personal mantenida sobre usted en nuestros sistemas (con sujeción al párrafo siguiente), cancelando su cuenta. Dichas actualizaciones, correcciones, cambios y eliminaciones no tendrán ningún efecto sobre otra información que mantengamos, o sobre la información que hayamos proporcionado a terceros de acuerdo con esta Política de Privacidad antes de dicha actualización, corrección, cambio o eliminación. Para proteger su privacidad y seguridad, podemos tomar medidas razonables (como solicitar una contraseña única) para verificar su identidad antes de concederle acceso al perfil o realizar correcciones. Usted es responsable de mantener en secreto su contraseña única y la información de su cuenta en todo momento.</p>
                    <p>Debe ser consciente de que no es tecnológicamente posible eliminar de nuestro sistema todos y cada uno de los registros de la información que nos ha proporcionado. La necesidad de realizar copias de seguridad de nuestros sistemas para proteger la información de pérdidas involuntarias significa que puede existir una copia de su información en un formato no borrable que nos resultará difícil o imposible de localizar. Inmediatamente después de recibir su solicitud, toda la información personal almacenada en las bases de datos que utilizamos activamente y en otros medios de fácil búsqueda será actualizada, corregida, modificada o eliminada, según corresponda, tan pronto como sea razonable y técnicamente posible.</p>
                    <p>Si usted es un usuario final y desea actualizar, eliminar o recibir cualquier información que tengamos sobre usted, puede hacerlo poniéndose en contacto con la organización de la que es cliente.</p>

                    <h2>Solicitud de eliminación de datos</h2>
                    
                    <p>Nuestros usuarios pueden solicitar la eliminación automática de su cuenta y todos los datos asociados a través de la configuración de su perfil. Tras la confirmación, una notificación emergente les informará que el proceso puede tardar hasta 72 horas. Una vez confirmada la solicitud, nuestro equipo de soporte técnico la recibirá y procederá a eliminar la cuenta dentro del plazo establecido. Se enviará un correo electrónico de confirmación al usuario una vez que se haya completado el proceso.</p>

                    <h2>Seguridad</h2>

                    <p>KAPI emplea medidas organizacionales, técnicas y administrativas razonables para proteger la información y Datos Personales que se encuentra bajo nuestro control. Desafortunadamente, ninguna transmisión de datos a través de internet o a través del sistema de almacenamiento de datos puede ser considerada como 100% segura.</p>
                    <p>Si existe alguna razón por la que crea que su relación y/o comunicación con nosotros ya no es segura (por ejemplo, si considera que la seguridad de cualquier cuenta que tenga con nosotros se encuentra comprometida), por favor notifiquenos el problema inmediatamente, comunicándose con nosotros según se indica en la sección de contacto.</p>

                    <h2>Responsable de Tratamiento de los Datos y Propietario</h2>
                    <p>KAPIAPP S.R.L comercializada como KAPI ('KAPI')</p>

                </section>
                <footer className="footer">
                    <span>KAPIAPP S.R.L es una marca registrada. © {new Date().getFullYear()}.</span>
                </footer>
            </React.Fragment>
        )
    }

    renderEng() {
        return (
            <React.Fragment>
                <section className="section-padding section-terms">
                    <h1>Privacy Policy</h1>
                    <p>This privacy policy describes the procedures on the collection, use and disclose of the information to KAPIAPP S.R.L, from here on referred to as KAPI (“KAPI”), provided by you (the “User”) when you are using our app or website. KAPI is committed to the protection of the privacy and the data security of our users. When we ask you to fill the personal information fields with which you can be identified, we do so by assuring that it only will be used in accordance with the terms of this document. However, this Privacy Policy may change over time or be updated and we encourage and emphasize that you should continually review this page to ensure that you agree to any such changes. </p>
                
                    <p>If you do not agree with any terms in this privacy policy, please do not access this website or provide any personal information.</p>

                    <h2>Information that is collected</h2>

                    <p>KAPI collects, by itself or through third parties, the following types of personal data: cookies, usage data, name, last name(s), telephone number, email address, gender, date of birth, password, city and image. If necessary, specific information might also be needed to process some orders, make a delivery or an invoice.</p>

                    <p>Other personal data collected may be described in other sections of this privacy policy or in other informative text provided at the time of taking the data.</p>

                    <p>Personal data can be provided free by the User or may be automatically collected by KAPI when it is used.</p>

                    <p>Unless otherwise stated, the use of cookies - or other tracking tools - by KAPI or by the third party service providers that use KAPI is for the purpose of identifying Users and storing their preferences, for the sole purpose of providing the services requested by the User.</p>

                    <p>If certain personal data is not provided by the User, KAPI may not be able to provide its services.</p>

                    <p>The User assumes responsibility for the personal data of third parties published or shared by KAPI, and declares that they are authorized to provide or disclose the respective data, thus exempting the responsible entity from any responsibility in this regard.</p>

                    <h2>Use of the collected data</h2>

                    <p>KAPI uses the information to provide the best possible service, particularly to keep a User registry, of orders if applicable, and improve the quality of our products and services, as well as for the following purposes: analytics, interacting with social networks and external platforms, interaction with support and feedback platforms, contacting the User, registration and authentication. Emails may be sent periodically through our site with special offers, new products and other advertising information that we consider relevant to you or that may provide you with some benefit. These emails will be sent to the address you provide and can be cancelled at any time.</p>

                    <h2>Cookies</h2>

                    <p>Cookie refers to a file that is sent in order to request permission to be stored on your device, when accepting, such a file is created and the cookie is then used to have information regarding web traffic, and also facilitates future visits to a recurring website. Another function that cookies have is that with them the websites can recognize you individually and therefore provide you with the best personalized service of their web.</p>

                    <p>KAPI uses cookies to identify the pages that are visited and their frequency. This information is used only for statistical analysis and then the information is permanently deleted. You can delete cookies at any time from your device. However, cookies help to provide a better service of websites, but cookies do not give access to information on your device or you, unless you wish to do so and provide it directly. You can accept or deny the use of cookies. However, most browsers accept cookies automatically as it serves to have a better web service. You can also change your device settings to decline cookies. If you decline, you may not be able to use some of our services.</p>

                    <h2>User control of personal information</h2>

                    <p>Users have the right, at any time, to know whether their personal data has been stored and can consult the data controller to learn about their contents and origin, to verify their accuracy and to ask for them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the data controller at the contact information set out above.</p>

                    <p>At any time you may restrict the collection or use of personal information that is provided to our website. Each time you are asked to fill out a form, such as the User registration form, you can check or uncheck the option to receive information by email. In case you have checked the option to receive our newsletter or advertising you can cancel it at any time.</p>

                    <p>KAPI will not sell, assign, or distribute personal information that is collected without your consent, unless required by a judge with a court order. </p>

                    <h2>Facebook permissions requested by Kickup.</h2>

                    <p>KAPI may require certain Facebook permissions which allow it to perform actions with the User’s Facebook account and to collect information, including personal data, from it. This service allows KAPI to connect to the User’s Facebook account, provided by Facebook Inc.</p>
                    <p>For more information on the permissions that follows, refer to the documentation of the Facebook permissions and the privacy policy of Facebook. The requested permits are as follows: name, current city, date of birth, email address, basic information.</p>

                    <h2>Updating and correcting information</h2>

                    <p>The rights you have to request updates or corrections to the information KAPI collects depend on your relationship with KAPI. Personnel may update or correct  information as detailed in our internal company employment policies.</p>
                    <p>Customers have the right to request restrictions on our use or disclose of personal identifiable information as follows. You can contact us in order to: (1) update or correct your personal data, (2) change your preferences regarding communications and other information you receive from us, or (3) delete your personal data we maintain on our systems (according to the following paragraph), by canceling your account. These updates, corrections, changes, and deletions will have no effect on other information that we maintain or information that we have provided to third parties in agreement with this Privacy Policy prior to such update, correction, change, or deletion. To protect your privacy and security, we may take reasonable steps (like requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for keeping the secrecy of your unique password and account information at all times.</p>
                    <p>You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Immediately after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed, or deleted, as appropriate, as soon as it is reasonably and technically feasible.</p>
                    <p>If you are an end-user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.
</p>
            
                    <h2>Request data deletion</h2>

                    <p>Our users can request to have their account and all associated data automatically deleted through their profile settings. Upon confirmation, a pop-up notification will inform them that the process may take up to 72 hours. Once confirmed, our technical support team will receive the request and proceed to delete the account within the established timeframe. A confirmation email will be sent to the user once the process is completed.</p>

                    <h2>Security</h2>

                    <p>KAPI employs organizational, technical and administrative measures designed to ensure the protection of the information and personal data under our control. Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. </p>
                    <p>If you have any reason to believe that your interaction and/or communication with us is no longer secure (e.g. if you feel that the security of any account has been compromised), please immediately notify the problem by contacting us as indicated in the contact section of this policy</p>

                    <h2>Data controller and owner</h2>
                    <p>KAPIAPP S.R.L marketed as KAPI (“KAPI”)</p>

                </section>


                <footer className="footer">
                    <span>KAPI is a trademark registered. © {new Date().getFullYear()}.</span>
                </footer>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                     <Navbar 
                        clickAbout={(nav) => { window.scrollTo(0, this.about.current.offsetTop - 80 - nav.current.clientHeight ) }} 
                        clickHow={(nav) => { window.scrollTo(0, this.how.current.offsetTop - nav.current.clientHeight ) }} 
                    />

               <LanguageContext.Consumer>
                {({ language }) => (
                  language.id === 'es' ? this.renderEsp() : this.renderEng()
                )}
               </LanguageContext.Consumer>
 
            </React.Fragment>
        )
    }

}

export default Terms;    
