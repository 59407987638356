import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '../../components/Language/Language';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';
import './home.css';
import MAN from '../../resources/img/man-wearing-blue-shirt-looking-on-ball-3684122.png';

class Home extends Component { 
    
    componentDidMount() {
        document.title = 'Kapi';
    }
    render() {
        return (
                <div>
                    <Navbar 
                        sel="about"
                        clickAbout={(nav) => { window.scrollTo(0, this.about.current.offsetTop - 80 - nav.current.clientHeight ) }} 
                        clickHow={(nav) => { window.scrollTo(0, this.how.current.offsetTop - nav.current.clientHeight ) }} 
                    />

                    <section className="section-padding section-download">
                        <div className="row section-home-banner">
                            <div className="col-xs-12 col-md-12">
                                    <div className="text-center">
                                        <label className="we-banner-title we"><Text tid="homeWe"></Text></label>
                                        <label className="we-banner-title">Kapi!</label>
                                    </div>
                                </div>
                        </div>
                    </section>

                    <section className="section-padding section-about" >
                        <div className="img-about">
                            <div className="new-form">
                                <h1><Text tid="homeNewForm"></Text></h1>
                            </div>
                            <div className="about-text-container">
                                <p className="about-text">
                                    <Text tid="textPlayers1"></Text>
                                </p>
                                <p className="about-text">
                                    <Text tid="textPlayers2"></Text>
                                </p>
                                <p className="about-text">
                                    <Text tid="textPlayers3"></Text>
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="section-padding section-about-black" style={{ paddingTop: 0 }}>
                    <div className='row aling-center'>
                        <div className="col-md-5 col-xs-12 text-center">
                            <img src={MAN} alt="app" className="img-resp img-man"></img>
                        </div>
                        <div className="col-md-7 col-xs-12 text-center">
                            <div className="about-text-container-black">
                                <p className="about-text">
                                    <Text tid="textPlayers4"></Text>
                                </p>
                                <p className="about-text">
                                    <Text tid="textPlayers5"></Text>
                                    <b className="bold-white"><Text tid="textPlayers6"></Text></b>
                                </p>
                            </div>
                        </div>
                    </div>
                    </section>

                    <section className="section-padding">
                        <div className="icons-section">
                            <div className="text-center pick-side">
                                <label ><Text tid="homePickSide"></Text></label>
                                <label className="we"><Text tid="homePickSide1"></Text></label>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 text-center">
                                    <Link to="/players">
                                        <div className="icon-card">
                                            <img src='/img/jugador.svg' className="img-fluid icon" alt="player"/>
                                            <div className="label-icon">
                                                <label className="title-label"><Text tid="homePlayers"></Text><div className="point"></div></label>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 text-center">
                                    <Link to="/fields">
                                        <div className="icon-card">
                                            <img src='/img/cancha.svg' className="img-fluid icon" alt="field"/>
                                            <div className="label-icon">
                                                <label className="title-label"><Text tid="homeFields"></Text><div className="point green"></div></label>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                
                    </section>
                    <ScrollTop />
                    <Footer/>
                </div>
        )
    }
}

export default Home;
