import React, { useContext } from 'react';

import { languageOptions } from '../languages';

import { LanguageContext } from './Language';

export default function LanguageSelector() {
  const languageContext = useContext(LanguageContext);

  const handleLanguageChange = (event) => {
    const selectedLanguage = languageOptions.find(item => item.id === event);
    languageContext.setLanguage(selectedLanguage);
  };

  return (
    <React.Fragment>
        <li><span className="flag es" onClick={() => { handleLanguageChange('es') }}></span></li>
        <li><span className="flag en" onClick={() => { handleLanguageChange('en') }}></span></li>
    </React.Fragment>
  );
};