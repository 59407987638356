import React , { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import axios from 'axios';

import NavbarField from '../../components/navbarField';
import { Text, LanguageContext } from '../../components/Language/Language';
import ScrollTop from '../../components/scrollTop';
import './fields.css';
import LOGO_BANNER from '../../resources/img/banner-fields.png';
import LOGO_FIELD from '../../resources/img/LOGO_fields.png';
import COMPUTER from '../../resources/img/COMPU.png';
import COMPUTER_ES from '../../resources/img/COMPU_es.png';
import MAP from '../../resources/img/map@2x.png';


class Fields extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            company: '',
            loadingMess: false,
        }
        this.about = React.createRef();
        this.how = React.createRef();
    }

    static contextType = LanguageContext;

    validateForm = (e) => {
        e.preventDefault();
        const { name, email, message, company } = this.state;
        let error = false;
        if (name.trim() === '' ) {
            toast.error(this.context.dictionary['nameRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        if (company.trim() === '' ) {
            toast.error(this.context.dictionary['nameCompanyRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            toast.error(this.context.dictionary['emailRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        if (message.trim() === '' ) {
            toast.error(this.context.dictionary['messageRequired'], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
            });
            error = true;
        }
        if (error) return;
            this.handleFormSubmit();
    }
    
    handleFormSubmit(e) {
        const { name, email, message, company } = this.state;
        this.setState({ loadingMess: true });
        Progress.show();
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}`,
          headers: { "content-type": "application/json", "Access-Control-Allow-Origin": "*" },
          data: { name, email, message, subject: 'Kapi Canchas', company }
        })
          .then(result => {
            if (result.data.sent) {
              this.setState({ 
                                error: false, 
                                loadingMess: false,
                                name: '',
                                email: '',
                                message: '',
                                company: '',
                            });
              toast.success(this.context.dictionary['contactSuccess'], {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
              });
            } else {
              toast.error(this.context.dictionary['contactError'], {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
              this.setState({ error: true, loadingMess: false });
            }
          })
          .catch(error => {
            this.setState({ error: error.message, loadingMess: false });
            toast.error(this.context.dictionary['contactError'], {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000
            });
          })
          .finally(() => { Progress.hide(); });
    }

    setTitle = (context) => 
    { 
        document.title = context.dictionary['titleFields'];
    }

    render() {
        return (
            <div>
                <NavbarField 
                    clickAbout={(nav) => { window.scrollTo(0, this.about.current.offsetTop - nav.current.clientHeight ) }} 
                    clickHow={(nav) => { window.scrollTo(0, this.how.current.offsetTop - nav.current.clientHeight ) }} 
                />
                <ToastContainer autoClose={false} />
                <section className="section-padding">
                    <img src={LOGO_BANNER} alt="banner" style={{ width:'100%', height: '70%' }}></img>
                </section>

                <section ref={this.about} className="section-padding section-lead">
                    <div className="row vertical-align">
                        <div className="col-xs-12 col-md-12 text-center">
                            <h1 className="kapi-title"><Text tid="kapiFieldTitle"></Text></h1>
                            <h1 className="kapi-title"><Text tid="kapiFieldTitle2"></Text></h1>
                            <span className="kapi-title-sub"><Text tid="kapiFieldTitle3"></Text></span>
                        </div>
                    </div>
                </section>

                <section className="section-padding" style={{ paddingBottom: 0, minHeight: 100 }}>
                    <div className="text-center">
                        <img src={LOGO_FIELD} alt="" className="logo-fields"></img>
                    </div>
                </section>
                <section className="section-padding section-software">
                    <div className="text-center fields-description">
                            <label className="kapi-software">F I E L D &nbsp; S O F T W A R E</label>
                        <p>
                            <span className="kapi-name"><Text tid="preKapiFieldText"></Text></span><Text tid="kapiFieldText"></Text>
                        </p>
                    </div>
                    <LanguageContext.Consumer>
                    {(context) => (
                        context.language.id === 'es' ?
                        (
                            <img src={COMPUTER_ES} alt="banner" style={{ width:'100%' }}></img>
                        ) : 
                        (
                            <img src={COMPUTER} alt="banner" style={{ width:'100%' }}></img>
                        )
                    )}
                    </LanguageContext.Consumer>
                </section>

                <section className="section-padding">
                    <div className="text-contact-section">
                        <h1 className="text-contact"><Text tid="textContactField"></Text></h1>
                        <h3 className="text-contact-sub"><Text tid="textContactSoon"></Text></h3>
                    </div>

                    <div className="contact-form-group">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="row text-center">
                                <div className="col-md-12 col-xs-12">
                                    <div style={{ textAlign: 'left', marginBottom: '2rem' }}>
                                        <span className="contact-font"><Text tid="textContactUs"></Text></span>
                                    </div>
                                    <form className="contact-form">
                                        <LanguageContext.Consumer>
                                            {(context) => (
                                                <React.Fragment>
                                                {this.setTitle(context)}
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                        <input type="text" placeholder={context.dictionary['contactName']} value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                        <input type="text" placeholder={context.dictionary['contactCompany']} value={this.state.company} onChange={(e) => { this.setState({ company: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                    <input type="email" placeholder="Email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }}  />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col col-xs-12">
                                                        <textarea rows="5" placeholder={context.dictionary['contactMessage']} value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} ></textarea>
                                                    </div>
                                                </div>
                                                </React.Fragment>
                                            )}
                                        </LanguageContext.Consumer>
                                        <div className="row">
                                            <div className="col col-xs-12">
                                                <button type="submit" className="btn theme-btn green" disabled={this.state.loadingMess} onClick={this.validateForm.bind(this)}><Text tid="contactSend"></Text></button>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="row">
                                <div className=" col-xs-12 col-sm-12 col-md-12 right-contact">
                                    <div style={{ marginBottom: '2rem' }}>
                                        <span className="contact-font">Talcahuano 758 1"B", Caba, Argentina</span>
                                    </div>
                                    <img src={MAP} alt="map" className="img-fluid map-img"></img>
                                    <br></br>
                                    <p><Text tid="contactFollow"></Text></p>
                                    <div className="socials-icons">
                                        <a href="#kapi" target="_blank" rel="noopener noreferrer">
                                        <img src={"/img/icons/Linkedin_2.svg"} alt="instagram" className="img-fluid icons-socials" />
                                        </a>
                                        <a href="#kapi" target="_blank" rel="noopener noreferrer">
                                        <img src={"/img/icons/Instagram_2.svg"} alt="Twitter" className="img-fluid icons-socials" />
                                        </a>
                                        <a href="#kapi" target="_blank" rel="noopener noreferrer">
                                        <img src={"/img/icons/Facebook_2.svg"} alt="TikTok_" className="img-fluid icons-socials" />
                                        </a>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                    
                </section>

                <ScrollTop className="green"/>

            </div>
        )
    }
}

export default Fields;