import React from 'react';
import { Route, BrowserRouter} from 'react-router-dom';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import { LanguageProvider } from './components/Language/Language';
import ScrollToTop from './components/scrollToTop';

import Home from './containers/Home/home';
import Players from './containers/Players/players';
import Fields from './containers/Fields/fields';

import Terms from './containers/Terms/terms';

function App() {
  return (
    <LanguageProvider>
      <div>
        <BrowserRouter>
          <React.Fragment>
            <ScrollToTop/>
            <Progress.Component
                style={{ background: '#99999978', height: '5px' }}
                thumbStyle={{ background: '#ffe447', height: '5px' }}
            />
            <Route exact path="/" component={Home} />
            <Route path="/players" component={Players} />
            <Route path="/fields" component={Fields} />
            <Route exact path="/Politicas_de_Privacidad" component={Terms} />
          </React.Fragment>
        </BrowserRouter>
      </div>
    </LanguageProvider>
  );
}

export default App;
